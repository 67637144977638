import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

export const _frontmatter = {
  "title": "First Session Back",
  "date": "2022-09-12",
  "author": "Andy B",
  "featuredImage": "cd1.jpeg"
};
const layoutProps = {
  _frontmatter
};
const MDXLayout = "wrapper";
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">
    <p>{`We had a fantastic first session back at the Bishops Cleeve CoderDojo yesterday, with the children showing off some great creativity and Scratch coding skills. The mentors and I were all really impressed!`}</p>
    <p><span parentName="p" {...{
        "className": "gatsby-resp-image-wrapper",
        "style": {
          "position": "relative",
          "display": "block",
          "marginLeft": "auto",
          "marginRight": "auto",
          "maxWidth": "1180px"
        }
      }}>{`
      `}<a parentName="span" {...{
          "className": "gatsby-resp-image-link",
          "href": "/static/bf32a7c21bfa5f65e42f615285282029/eea4a/cd1.jpg",
          "style": {
            "display": "block"
          },
          "target": "_blank",
          "rel": "noopener"
        }}>{`
    `}<span parentName="a" {...{
            "className": "gatsby-resp-image-background-image",
            "style": {
              "paddingBottom": "66.77966101694915%",
              "position": "relative",
              "bottom": "0",
              "left": "0",
              "backgroundImage": "url('data:image/jpeg;base64,/9j/2wBDABALDA4MChAODQ4SERATGCgaGBYWGDEjJR0oOjM9PDkzODdASFxOQERXRTc4UG1RV19iZ2hnPk1xeXBkeFxlZ2P/2wBDARESEhgVGC8aGi9jQjhCY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2P/wgARCAANABQDASIAAhEBAxEB/8QAGQAAAgMBAAAAAAAAAAAAAAAAAAQBAgMF/8QAFgEBAQEAAAAAAAAAAAAAAAAAAQAC/9oADAMBAAIQAxAAAAG63PcLUg0f/8QAGhAAAgMBAQAAAAAAAAAAAAAAAQIAAxMRMv/aAAgBAQABBQI2KBqzNpbGck1+Oz//xAAVEQEBAAAAAAAAAAAAAAAAAAAQEf/aAAgBAwEBPwGn/8QAFREBAQAAAAAAAAAAAAAAAAAAEBH/2gAIAQIBAT8Bh//EABgQAAIDAAAAAAAAAAAAAAAAAAAxAhAR/9oACAEBAAY/AjIiHf8A/8QAGRABAAMBAQAAAAAAAAAAAAAAAQARITFR/9oACAEBAAE/ITe3eRyVD0jfwTe2jxFz/9oADAMBAAIAAwAAABCMH//EABURAQEAAAAAAAAAAAAAAAAAAAEQ/9oACAEDAQE/EFM//8QAFREBAQAAAAAAAAAAAAAAAAAAARD/2gAIAQIBAT8QAT//xAAaEAEBAQEAAwAAAAAAAAAAAAABEQAhMUFR/9oACAEBAAE/EEGQkPZnoSlYeM1IL0AMxayGi9TdEAvzBZv/2Q==')",
              "backgroundSize": "cover",
              "display": "block"
            }
          }}></span>{`
  `}<img parentName="a" {...{
            "className": "gatsby-resp-image-image",
            "alt": "Children working on coding projects, at a row of computers",
            "title": "Children working on coding projects, at a row of computers",
            "src": "/static/bf32a7c21bfa5f65e42f615285282029/fbd2c/cd1.jpg",
            "srcSet": ["/static/bf32a7c21bfa5f65e42f615285282029/1c91a/cd1.jpg 295w", "/static/bf32a7c21bfa5f65e42f615285282029/1c72d/cd1.jpg 590w", "/static/bf32a7c21bfa5f65e42f615285282029/fbd2c/cd1.jpg 1180w", "/static/bf32a7c21bfa5f65e42f615285282029/eea4a/cd1.jpg 1280w"],
            "sizes": "(max-width: 1180px) 100vw, 1180px",
            "style": {
              "width": "100%",
              "height": "100%",
              "margin": "0",
              "verticalAlign": "middle",
              "position": "absolute",
              "top": "0",
              "left": "0"
            },
            "loading": "lazy",
            "decoding": "async"
          }}></img>{`
  `}</a>{`
    `}</span></p>
    <p>{`This was our first session following the `}<a parentName="p" {...{
        "href": "https://projects.raspberrypi.org/en/paths"
      }}>{`learning paths`}</a>{`, and most children started with the Introduction to Scratch path.`}</p>
    <p>{`The first project involves creating talking characters. We had a princess floating off into space, a dog waking up its owner who had fallen asleep in the park as well as a snowman melting on the beach (with some great melting animation!), and finally a beautifully hand-drawn character who told randomly generated stories.`}</p>
    <p><span parentName="p" {...{
        "className": "gatsby-resp-image-wrapper",
        "style": {
          "position": "relative",
          "display": "block",
          "marginLeft": "auto",
          "marginRight": "auto",
          "maxWidth": "1180px"
        }
      }}>{`
      `}<a parentName="span" {...{
          "className": "gatsby-resp-image-link",
          "href": "/static/78fd2efe71d685d42b54d004e1352e23/eea4a/cd2.jpg",
          "style": {
            "display": "block"
          },
          "target": "_blank",
          "rel": "noopener"
        }}>{`
    `}<span parentName="a" {...{
            "className": "gatsby-resp-image-background-image",
            "style": {
              "paddingBottom": "80%",
              "position": "relative",
              "bottom": "0",
              "left": "0",
              "backgroundImage": "url('data:image/jpeg;base64,/9j/2wBDABALDA4MChAODQ4SERATGCgaGBYWGDEjJR0oOjM9PDkzODdASFxOQERXRTc4UG1RV19iZ2hnPk1xeXBkeFxlZ2P/2wBDARESEhgVGC8aGi9jQjhCY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2P/wgARCAAQABQDASIAAhEBAxEB/8QAGAAAAwEBAAAAAAAAAAAAAAAAAAEEAgX/xAAWAQEBAQAAAAAAAAAAAAAAAAABAgP/2gAMAwEAAhADEAAAAU+ZcN5kzv8A/8QAGxAAAQQDAAAAAAAAAAAAAAAAAgAREyIBAxL/2gAIAQEAAQUCmF5LrZhzAq9gv//EABYRAQEBAAAAAAAAAAAAAAAAAAASAf/aAAgBAwEBPwGUa//EABYRAQEBAAAAAAAAAAAAAAAAAAASEf/aAAgBAgEBPwGsU//EABwQAAIBBQEAAAAAAAAAAAAAAAABAhAhMTJhcf/aAAgBAQAGPwKxzyjawKTcTZH/xAAbEAADAAMBAQAAAAAAAAAAAAAAAREhUXFBof/aAAgBAQABPyGDw4/hOL9WaFiGID4MWjbmUbncP//aAAwDAQACAAMAAAAQ0O//xAAXEQEBAQEAAAAAAAAAAAAAAAABACEx/9oACAEDAQE/ENdhDC//xAAWEQEBAQAAAAAAAAAAAAAAAAAAAVH/2gAIAQIBAT8QwWn/xAAbEAEAAgMBAQAAAAAAAAAAAAABABEhMUFhkf/aAAgBAQABPxBH0fpXwzpxgbIKQteVAhk42KilQgWLDeTsALuMnNcn/9k=')",
              "backgroundSize": "cover",
              "display": "block"
            }
          }}></span>{`
  `}<img parentName="a" {...{
            "className": "gatsby-resp-image-image",
            "alt": "A demo of a project which autogenerates stories",
            "title": "A demo of a project which autogenerates stories",
            "src": "/static/78fd2efe71d685d42b54d004e1352e23/fbd2c/cd2.jpg",
            "srcSet": ["/static/78fd2efe71d685d42b54d004e1352e23/1c91a/cd2.jpg 295w", "/static/78fd2efe71d685d42b54d004e1352e23/1c72d/cd2.jpg 590w", "/static/78fd2efe71d685d42b54d004e1352e23/fbd2c/cd2.jpg 1180w", "/static/78fd2efe71d685d42b54d004e1352e23/eea4a/cd2.jpg 1280w"],
            "sizes": "(max-width: 1180px) 100vw, 1180px",
            "style": {
              "width": "100%",
              "height": "100%",
              "margin": "0",
              "verticalAlign": "middle",
              "position": "absolute",
              "top": "0",
              "left": "0"
            },
            "loading": "lazy",
            "decoding": "async"
          }}></img>{`
  `}</a>{`
    `}</span></p>
    <p>{`Tickets are now available for the next sessions,
`}<a parentName="p" {...{
        "href": "https://zen.coderdojo.com/dojos/gb/cheltenham/bishops-cleeve-bishops-cleeve-library"
      }}>{`book your free place now`}</a>{`!`}</p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      